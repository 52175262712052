<template>
  <div>
    <div v-if="!formVisible" class="view-wrap">
      <div class="search">
        <el-form :inline="true">
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="请输入编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="处置时间">
            <el-date-picker
              v-model="searcForm.date"
              type="daterange"
              range-separator="至"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
            <el-button type="default" plain @click="reset"> 清空</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right">
        <el-button type="primary" @click="openRecycleList">
          <i class="el-icon-plus" /> 回收废弃物列表</el-button
        >
        <el-button type="primary" @click="add">
          <i class="el-icon-plus" /> 新增处置记录</el-button
        >
        <el-button type="primary" plain @click="exportData"> 导出</el-button>
        <el-button type="danger" plain @click="deleteBatch">
          批量删除</el-button
        >
      </div>
      <div class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
          @selectChange="selectChange"
        >
          <div slot="operation" slot-scope="row">
            <el-button type="text" @click="WatchData(row.data)">查看</el-button>
            <el-button type="text" @click="EditData(row.data)">修改</el-button>
            <el-button type="text" @click="DeleteData(row.data)"
              >删除</el-button
            >
            <el-button type="text" @click="exportData(row.data.id)"
              >导出</el-button
            >
          </div>
        </SimpleTable>
      </div>
    </div>
    <safeLedgerForm ref="editForm" v-if="formVisible" @back="backList" />
    <!-- 回收废弃物 -->
    <miaDialog ref="recycleDialog" v-if="recycleListVisible">
      <div slot="content">
        <div style="text-align: right">
          <el-button type="primary" @click="addRecycle">
            <i class="el-icon-plus" /> 新增废弃物</el-button
          >
        </div>
        <SimpleTable
          :data="recycleData"
          :tableFields="recycleTableFields"
          :currentPage="recycleSearch.pageNum"
          :pageSize="recycleSearch.pageSize"
          :total="recycleTotal"
          :showPagination="false"
        >
          <div slot="operation" slot-scope="row">
            <el-button type="text" @click="EditRecycleData(row.data)"
              >修改</el-button
            >
            <el-button type="text" @click="DeleteRecycleData(row.data)"
              >删除</el-button
            >
          </div>
        </SimpleTable>
      </div>
    </miaDialog>
    <!-- 新增/修改废弃物 -->
    <miaDialog ref="AddOrEditForm" v-if="recycleVisible">
      <div slot="content">
        <el-form
          ref="form"
          style="width: 70%; margin: 0 auto"
          :model="recycleForm"
          :rules="recycleRules"
          label-width="120px"
        >
          <el-form-item label="农药名称" prop="name" key="name">
            <el-input
              v-model="recycleForm.name"
              placeholder="请输入农药名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="单位/规格" prop="spec" key="spec">
            <el-input
              v-model="recycleForm.spec"
              placeholder="请输入单位/规格"
            ></el-input>
          </el-form-item>
          <el-form-item label="废弃物类别" prop="type" key="type">
            <el-select
              v-model="recycleForm.type"
              style="width: 100%"
              filterable
              clearable
              allow-create
              placeholder="请选择类别"
            >
              <el-option
                v-for="(item, Index) in types"
                :key="Index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="废弃物来源" prop="source" key="source">
            <el-select
              v-model="recycleForm.source"
              style="width: 100%"
              filterable
              clearable
              allow-create
              placeholder="请选择来源"
            >
              <el-option
                v-for="(item, Index) in sources"
                :key="Index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="回收日期" prop="recycleDate" key="recycleDate">
            <el-date-picker
              v-model="recycleForm.recycleDate"
              type="date"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="暂存仓库日期" prop="saveDate" key="saveDate">
            <el-date-picker
              v-model="recycleForm.saveDate"
              type="date"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="回收数量" prop="recycleNum" key="recycleNum">
            <el-input
              type="number"
              v-model="recycleForm.recycleNum"
              placeholder="请输入数量"
            ></el-input>
          </el-form-item>
          <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button type="primary" @click="submitData">提交</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </el-form>
      </div>
    </miaDialog>
  </div>
</template>
<script>
import {
  getRecycleLedgerList,
  delRecycleLedger,
  getRecycleList,
  addRecycle,
  editRecycle,
  delRecycle,
  recycleLedgerExport,
} from "@/api/recycleLedger";
import safeLedgerForm from "./form.vue";
export default {
  components: { safeLedgerForm },
  data() {
    return {
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        date: [],
        param: "",
      },
      UpdateType: null,
      tableData: [],
      total: 0,
      tableItem: [
        { label: "", type: "selection" },
        { label: "操作", type: "operation" },
        { prop: "no", label: "台账编号", type: "default" },
        { prop: "disposeNum", label: "处置数量(合计)", type: "default" },
        { prop: "disposeDate", label: "处置时间", type: "default" },
        { prop: "disposeWhither", label: "处置去向", type: "default" },
      ],

      Visible: false,
      formVisible: false,
      checkList: [], // 列表已选项
      recycleListVisible: false, // 回收列表展示状态
      recycleSearch: {
        pageNum: 1,
        pageSize: 10,
        param: "",
      },
      recycleTotal: 0,
      recycleData: [],
      recycleTableFields: [
        { label: "操作", type: "operation" },
        { prop: "name", label: "回收农药名称", type: "default" },
        { prop: "spec", label: "单位/规格", type: "default" },
        { prop: "recycleDate", label: "回收日期", type: "default" },
        { prop: "recycleNum", label: "回收数量", type: "number" },
        { prop: "type", label: "农药废弃物类别", type: "number" },
        { prop: "source", label: "农药废弃物来源", type: "number" },
        { prop: "saveDate", label: "暂存仓库日期", type: "number" },
      ],
      recycleVisible: false, // 新增废弃物弹窗
      recycleForm: {
        name: "",
      },
      recycleRules: {
        name: [
          { required: true, message: "农药名称不能为空", trigger: "blur" },
        ],
        type: [
          { required: true, message: "废弃物类别不能为空", trigger: "blur" },
        ],
        source: [
          { required: true, message: "废弃物类别不能为空", trigger: "blur" },
        ],
        spec: [
          { required: true, message: "单位/规格不能为空", trigger: "blur" },
        ],
        recycleDate: [
          {
            required: true,
            message: "回收日期不能为空",
            trigger: "change",
          },
        ],
        saveDate: [
          {
            required: true,
            message: "暂存仓库日期不能为空",
            trigger: "change",
          },
        ],
        recycleNum: [
          { required: true, message: "暂存仓库日期不能为空", trigger: "blur" },
        ],
      },
      types: [
        {
          name: "空包装",
          id: "空包装",
        },
        {
          name: "过期",
          id: "过期",
        },
      ],
      sources: [
        {
          name: "客户处回收",
          id: "客户处回收",
        },
        {
          name: "经营回收",
          id: "经营回收",
        },
      ],
    };
  },
  activated() {
    // this.GetCustomerType();
    this.getData();
  },
  methods: {
    WatchData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, true);
      });
    },
    add() {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(null, false);
      });
    },

    selectChange(val) {
      this.checkList = val;
    },
    backList() {
      this.formVisible = false;
      this.getData();
    },
    getData() {
      const params = { ...this.searcForm };
      if (params.checkPlace && params.checkPlace.length > 0) {
        params.checkPlace = params.checkPlace.join(";");
      }
      if (params.date) {
        params.startDate = params.date[0];
        params.endDate = params.date[1];
      }
      getRecycleLedgerList(params).then((res) => {
        if (res.code === 1) {
          const { records, current, size, total } = res.data;
          this.tableData = records;
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 新增
    UpDate() {
      // this.$router.push({ path: "/data/customerForm" });
      this.AddVisible = true;
    },
    // 修改
    EditData(data) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.editForm.init(data.id, false);
      });
      // this.$router.push({ path: "/data/EditCustomer", query: { id: data.id } });
    },
    refresh() {
      this.getData();
    },
    exportData(id) {
      // 导出
      recycleLedgerExport(id).then((data) => {
        const fileName = data.fileName || "农药废弃物回收和处置记录.docx";
        //   res.data:请求到的二进制数据
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //1.创建一个blob
        const link = document.createElement("a"); //2.创建一个a链接
        link.download = fileName; //3.设置名称
        link.style.display = "none"; // 4.默认不显示
        link.href = URL.createObjectURL(blob); // 5.设置a链接href
        document.body.appendChild(link); //6.将a链接dom插入当前html中
        link.click(); //7.点击事件
        URL.revokeObjectURL(link.href); //8.释放url对象
        document.body.removeChild(link); //9.移除a链接dom
      });
    },
    deleteBatch() {
      const ids = this.checkList.map((v) => {
        return v.id;
      });
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delRecycleLedger(ids.join(",")).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    DeleteData(data) {
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delRecycleLedger(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    }, // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },

    reset() {
      this.searcForm = {
        date: "",
        param: "",
      };
    },
    openRecycleList() {
      // 回收废弃列表
      this.recycleListVisible = true;

      this.$nextTick(() => {
        this.$refs.recycleDialog.init("回收废弃物列表");
      });
      this.getRecycle();
    },
    getRecycle() {
      const params = { ...this.recycleSearch, isBind: 2 };

      getRecycleList(params).then((res) => {
        if (res.code === 1) {
          // const { records, current, size, total } = res.data;
          this.recycleData = res.data;
          // this.recycleTotal = total;
          // this.searcForm.pageNum = current;
          // // 当没有数据
          // if (records.length === 0) {
          //   // 判断是否为第一页
          //   if (current === 1) {
          //     this.recycleSearch.pageNum = 1;
          //     return;
          //   } else {
          //     // 不是回退再重新搜索
          //     this.recycleSearch.pageNum = current - 1;
          //     this.getRecycle();
          //   }
          // }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    recycleChangePageSize(val) {
      this.recycleSearch.pageSize = val;
      this.recycleSearch.pageNum = 1;
      this.getRecycle();
    },
    recycleChangeCurrentPage(val) {
      this.recycleSearch.pageNum = val;
      this.getRecycle();
    },
    // 客户期初设置
    addRecycle(data) {
      this.recycleVisible = true;
      this.recycleForm = {};
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("新增废弃物");
      });
    },
    EditRecycleData(data) {
      this.recycleVisible = true;
      this.recycleForm = data;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("新增废弃物");
      });
    },
    DeleteRecycleData(data) {
      this.$confirm(`是否删除该记录？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        delRecycle(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getRecycle();
        });
      });
    },
    // 客户期初设置提交
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.recycleForm.id) {
            editRecycle(this.recycleForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getRecycle();
              } else {
                this.$message.error(msg);
              }
            });
          } else {
            addRecycle(this.recycleForm).then((res) => {
              const { code, msg } = res;
              if (code === 1) {
                this.$message.success(msg);
                this.close();
                this.getRecycle();
              } else {
                this.$message.error(msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
  },
};
</script>
